import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

export type ColorSchema = 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    neutral: string;
  }

  interface SimplePaletteColorOptions {
    lighter: string;
    darker: string;
    '-1'?: string;
    '+1'?: string;
    '+2'?: string;
    '+3'?: string;
    '+4'?: string;
  }

  interface PaletteColor {
    lighter: string;
    darker: string;
    '-1'?: string;
    '+1'?: string;
    '+2'?: string;
    '+3'?: string;
    '+4'?: string;
    contrastText: string;
  }

  interface Palette {
    custom: typeof CUSTOM;
    neutral: typeof NEUTRAL;
    tetriary: typeof TETRIARY;
  }

  interface PaletteOptions {
    custom: typeof CUSTOM;
    neutral: typeof NEUTRAL;
    tetriary: typeof TETRIARY;
  }
}

// SETUP COLORS

const CUSTOM = {
  red: '#CC2600',
  blue: '#0C63E7',
  grey: '#E6EBF0',
  green: '#00A76B',
};

const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
};

const NEUTRAL = {
  900: '#1B232C',
  800: '#4E5A65',
  700: '#637381',
  600: '#7D8D9B',
  500: '#9DA7B2',
  400: '#C5CCD3',
  300: '#E8EBEE',
  200: '#F6F7F8',
  100: '#FFFFFF',
};

const TETRIARY = {
  '-1': '#00849C',
  dark: '#00849C',
  main: '#00B8D9',
  light: '#AAE7F2',
  '+1': '#AAE7F2',
  contrastText: '#FFFFFF',
};

const PRIMARY = {
  '-1': '#0D41E1',
  lighter: '#D6E4FF',
  light: '#84A9FF',
  main: '#3366FF',
  dark: '#1939B7',
  darker: '#091A7A',
  contrastText: '#FFFFFF',
  '+1': '#AFC8ED',
  '+2': '#BDD7FF',
  '+3': '#DDEBFF',
  '+4': '#EBF3FF',
};

const SECONDARY = {
  lighter: '#D6E4FF',
  light: '#84A9FF',
  main: '#3366FF',
  dark: '#1939B7',
  darker: '#091A7A',
  contrastText: '#FFFFFF',
};

const INFO = {
  lighter: '#CAFDF5',
  light: '#61F3F3',
  main: '#00B8D9',
  dark: '#006C9C',
  darker: '#003768',
  contrastText: '#FFFFFF',
};

const SUCCESS = {
  lighter: '#DDF8EB',
  light: '#AAEDCD',
  main: '#00A76B',
  dark: '#008F63',
  darker: '#0A5554',
  contrastText: '#FFFFFF',
};

const WARNING = {
  '-1': '#E86C00',
  '+1': '#FCDFA7',
  lighter: '#FFF5CC',
  light: '#FCDFA7',
  main: '#FD8900',
  dark: '#E86C00',
  darker: '#7A4100',
  contrastText: GREY[800],
};

const ERROR = {
  lighter: '#FFE9D5',
  light: '#FFAC82',
  main: '#FF5630',
  dark: '#B71D18',
  darker: '#7A0916',
  contrastText: '#FFFFFF',
};

const COMMON = {
  custom: CUSTOM,
  neutral: NEUTRAL,
  tetriary: TETRIARY,
  common: { black: '#000000', white: '#FFFFFF' },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  divider: alpha(GREY[500], 0.24),
  action: {
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

const light = {
  ...COMMON,
  mode: 'light',
  text: {
    primary: GREY[800],
    secondary: GREY[600],
    disabled: GREY[500],
  },
  background: {
    paper: '#FFFFFF',
    default: '#FFFFFF',
    neutral: GREY[200],
  },
  action: {
    ...COMMON.action,
    active: GREY[600],
  },
} as const;

const dark = {
  ...COMMON,
  mode: 'dark',
  text: {
    primary: '#FFFFFF',
    secondary: GREY[500],
    disabled: GREY[600],
  },
  background: {
    paper: GREY[800],
    default: GREY[900],
    neutral: alpha(GREY[500], 0.16),
  },
  action: {
    ...COMMON.action,
    active: GREY[500],
  },
} as const;

export const getPalette = (themeMode: 'light' | 'dark') => (themeMode === 'light' ? light : dark);
