import { httpClient } from '@/libs/axios/http-client';
import type { PaginatedList, PaginationParams } from '../@types/pagination.types';
import type { Receipt } from './receipts.types';

export type FetchReceiptsParams = PaginationParams & {
  date_from?: string;
  date_to?: string;
  record_statuses?: number[];
  query?: string;
  user_id?: number;
};

export type FetchReceiptParams = {
  receiptId: number;
};

export const ReceiptsApi = {
  fetchReceipts: async (params: FetchReceiptsParams) => {
    const { data } = await httpClient.get<PaginatedList<'items', Receipt>>('receipts', { params });
    return data;
  },

  fetchReceipt: async ({ receiptId }: FetchReceiptParams) => {
    const { data } = await httpClient.get<Receipt>(`receipts/${receiptId}`);
    return data;
  },
};
