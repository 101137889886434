import { Fragment } from 'react';
import { Typography } from '@mui/material';

import { DEFAULT_DATE_FORMAT, DEFAULT_FULL_TIME_FORMAT } from '@/constants/date-formats.constants';
import { formatDateTime } from '@/helpers/format.helpers';

type DataGridDateTimeCellProps = {
  date: string | number | Date;
};

export const DataGridDateTimeCell = (props: DataGridDateTimeCellProps) => {
  const { date } = props;

  return (
    <Fragment>
      <Typography component="p" variant="Body/medium">
        {formatDateTime(date, DEFAULT_DATE_FORMAT)}
      </Typography>
      <Typography component="p" variant="Body/small" color="neutral.700">
        {formatDateTime(date, DEFAULT_FULL_TIME_FORMAT)}
      </Typography>
    </Fragment>
  );
};
