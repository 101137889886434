import { PharmacistsParams } from '@/api/domains/pharmacists.api';
import { paramsToQueryKey } from '@/helpers/transformation.helpers';

// ----------------------------------------------------------------------

const PREFIX = 'pharmacists' as const;

// ----------------------------------------------------------------------

export const PharmacistsQueryKeys = {
  root: [PREFIX],

  getPharmacist: (pharmacistId?: number | string) => [PREFIX, 'view', pharmacistId?.toString()],

  getPharmacists: (params?: PharmacistsParams) => paramsToQueryKey([PREFIX, 'list'], params),
};
