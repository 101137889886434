import { Box, BoxProps, styled } from '@mui/material';

export type TextLabelProps = BoxProps & React.PropsWithChildren;

const StyledRoot = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 16,
  padding: '0px 6px',
  backgroundColor: theme.palette.neutral[500],
  color: theme.palette.neutral[100],
  borderRadius: '100px',
}));

export const TextLabel = (props: TextLabelProps) => <StyledRoot {...props} />;
