import { PharmacistsApi } from '@/api/domains/pharmacists.api';
import { PharmacistsQueryKeys } from '@/api/domains/pharmacists.query-keys';
import { useQuery } from '@tanstack/react-query';

type UsePharmacistParams = {
  pharmacistId: number;
};

export const usePharmacist = ({ pharmacistId }: UsePharmacistParams) => {
  const isEnabled = pharmacistId !== undefined;

  return useQuery({
    queryKey: PharmacistsQueryKeys.getPharmacist(pharmacistId),
    queryFn: async () => {
      if (isEnabled) {
        return await PharmacistsApi.fetchPharmacist(pharmacistId);
      }
    },
    refetchOnWindowFocus: false,
    enabled: isEnabled,
  });
};
