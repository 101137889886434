import { Fragment, type PropsWithChildren } from 'react';
import { Box, Stack, SvgIcon, Tooltip, Typography, type SvgIconProps, type TooltipProps } from '@mui/material';

import { ReactComponent as FactoryIcon } from '@/assets/icons-2.0/factory-line.svg';
import { ReactComponent as LicenseHolderIcon } from '@/assets/icons-2.0/license.svg';
import { Maker } from '@/api/domains/makers.types';
import { formatMakerName } from '@/helpers/format.helpers';

type LicenseHolderAndMakerInfoProps = PropsWithChildren &
  Pick<ItemTooltipProps, 'placement' | 'size'> & {
    licenseHolder?: Pick<Maker, 'country_name' | 'name'> | null;
    maker: Pick<Maker, 'country_name' | 'name'>;
  };

type ItemTooltipProps = Omit<TooltipProps, 'title' | 'children'> & {
  name: string;
  country: string;
  size?: SvgIconProps['fontSize'];
  slotProps?: {
    icon?: Omit<SvgIconProps, 'size'>;
  };
  tooltipType?: 'licenseHolder' | 'maker';
};

const ItemTooltip = (props: ItemTooltipProps) => {
  const { name, country, size = 'medium', slotProps, tooltipType, ...tooltipProps } = props;

  const isMaker = tooltipType === 'maker';

  return (
    <Tooltip
      arrow
      title={
        <Fragment>
          <Typography component="div" variant="Body/small" color="neutral.500">
            {isMaker ? 'Производитель' : 'Владелец лицензии'}
          </Typography>
          <Typography variant="Body/small" color="neutral.100">
            {formatMakerName(name, country)}
          </Typography>
        </Fragment>
      }
      {...tooltipProps}
    >
      <Box component="span" display="inline-flex">
        <SvgIcon
          component={isMaker ? FactoryIcon : LicenseHolderIcon}
          fontSize={size}
          inheritViewBox
          sx={{ color: 'neutral.600' }}
          {...slotProps?.icon}
        />
      </Box>
    </Tooltip>
  );
};

export const LicenseHolderAndMakerInfo = (props: LicenseHolderAndMakerInfoProps) => {
  const { licenseHolder, maker, children, ...tooltipProps } = props;

  return (
    <Stack direction="row" spacing={0.5} alignItems="center">
      {licenseHolder && (
        <ItemTooltip
          name={licenseHolder.name}
          country={licenseHolder.country_name}
          tooltipType="licenseHolder"
          {...tooltipProps}
        />
      )}
      <ItemTooltip name={maker.name} country={maker.country_name} tooltipType="maker" {...tooltipProps} />
      {children}
    </Stack>
  );
};
