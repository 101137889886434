import { useMemo } from 'react';
import { useParams } from 'react-router';
import { isEmpty, isNil } from 'lodash';

import type { DataGridProps } from '@/components/DataGrid';
import type { ReceiptInfoDataGridRow, ReceiptInfoDataGridSelectedRowsData } from '../types';
import { useReceiptInfoStoreActions } from '../receipt-info.store';
import { PharmacistsControlDetailReceiptInfoPathParams } from '@/router/paths/dorim-promo.path';
import { useReceiptInfo } from '../useReceiptInfo';

export const useReceiptInfoDataGrid = () => {
  const { receiptId } = useParams<PharmacistsControlDetailReceiptInfoPathParams>();

  const { setSelectedRows, resetSelectedRows } = useReceiptInfoStoreActions();

  const { data: receipt, isLoading } = useReceiptInfo({ receiptId });

  const rows: ReceiptInfoDataGridRow[] = useMemo(() => {
    if (!receipt) return [];

    return receipt.items.map(item => ({
      id: item.id,
      drugInReceipt: item,
      drugInCatalog: item.drug,
      dorimPromo: item.dorim_promo,
      count: item.count,
      price: item.price,
      sum: item.count * item.price,
    }));
  }, [receipt]);

  const handleSelectionChange: DataGridProps['onSelectionChange'] = ({ selected }) => {
    if (isNil(selected)) {
      return;
    }

    if (isEmpty(selected)) {
      resetSelectedRows();
      return;
    }

    setSelectedRows(selected as ReceiptInfoDataGridSelectedRowsData);
  };

  return {
    rows,
    isLoading,
    handleSelectionChange,
  };
};
