import { Label } from '@/components/labels';
import {
  RECEIPT_STATUS_UNKNOWN,
  ReceiptColorByStatusId,
  ReceiptStatusId,
  ReceiptStatusNameByStatusId,
} from '@/constants/receipt-status.constants';

type ReceiptStatusProps = {
  status: ReceiptStatusId;
};

export const ReceiptStatus = (props: ReceiptStatusProps) => {
  const { status } = props;

  const statusName = ReceiptStatusNameByStatusId[status] || ReceiptStatusNameByStatusId[RECEIPT_STATUS_UNKNOWN];
  const color = ReceiptColorByStatusId[status];

  return <Label color={color}>{statusName}</Label>;
};
