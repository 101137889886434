import { Card, Stack, styled, Typography } from '@mui/material';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

const StyledRoot = styled(Card)(({ theme }) => ({
  width: '100%',
  flexShrink: 0,
  border: `1px solid ${theme.palette.error.main}`,
  padding: theme.spacing(3),
})) as typeof Card;

type CancelReasonCardProps = {
  reason: string;
};

export const CancelReasonCard = (props: CancelReasonCardProps) => {
  const { reason } = props;

  return (
    <StyledRoot>
      <Stack direction="row" spacing={3} alignItems="center">
        <Stack direction="row" spacing={1}>
          <ErrorOutlineOutlinedIcon color="error" />
          <Typography variant="Body/Bold/large" color="error">
            Причины отмены
          </Typography>
        </Stack>

        <Typography variant="Body/medium">{reason}</Typography>
      </Stack>
    </StyledRoot>
  );
};
