import { PaginatedList, PaginationParams } from '@/api/@types/pagination.types';
import { Pharmacist } from '@/api/domains/pharmacists.types';
import { httpClient } from '@/libs/axios/http-client';

// ----------------------------------------------------------------------

export type PharmacistsParams = PaginationParams & {
  query?: string;
};

export type AddBalanceParams = {
  amount: number;
  notes: string;
  type: number;
};

// ----------------------------------------------------------------------

export const PharmacistsApi = {
  fetchPharmacists: async (params: PharmacistsParams) => {
    const { data } = await httpClient.get<PaginatedList<'items', Pharmacist>>('provisor', { params });

    return data;
  },

  fetchPharmacist: async (pharmacistId: number) => {
    const { data } = await httpClient.get<Pharmacist>(`provisor/${pharmacistId}`);

    return data;
  },

  addBalance: async (userId: number, params: AddBalanceParams) => {
    await httpClient.post<string>(`/users/${userId}/balance`, params);
  },
};
