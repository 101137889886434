import { useEffect, useRef, useTransition } from 'react';
import { useMountedState } from 'react-use';

import type { DataGridPaginationProps } from './types';
import { useDataGridInstance } from '../useDataGridStore';

export const useDataGridPagination = (props: DataGridPaginationProps) => {
  const { instanceId, itemsPerPage = 50, dataSource, pagesLength = 0, autoScrollOnLoad = true, onShowMore } = props;
  const dataSourceLength = dataSource.length;

  const { instance } = useDataGridInstance(instanceId);

  const wasShowMoreRef = useRef(false);
  const handleShowMore = () => {
    wasShowMoreRef.current = true;
    onShowMore?.();
  };

  const isMounted = useMountedState();
  const [, startTransition] = useTransition();

  useEffect(() => {
    if (!wasShowMoreRef.current) {
      return;
    }

    if (autoScrollOnLoad) {
      startTransition(() => {
        if (!instance) {
          return;
        }

        const currentPage = Math.ceil(dataSourceLength / itemsPerPage);
        const lastLoadedItemsLength = itemsPerPage - (itemsPerPage * currentPage - dataSourceLength);
        const nextIndex = Math.max(dataSourceLength - lastLoadedItemsLength, 0);

        instance.scrollToIndex(nextIndex, { direction: 'top', duration: 500 }, () => {
          if (isMounted()) {
            instance.setActiveIndex(nextIndex);
            instance.focus();
          }
        });
      });
    }
  }, [dataSourceLength, itemsPerPage, pagesLength, instance]);

  return { handleShowMore };
};
