import type { PropsWithChildren } from 'react';
import { Stack } from '@mui/material';
import { DataGridActionbar } from '@/components/DataGrid';
import { ReceiptInfoInlineCheckButton, ReceiptInfoInlineCheckButtonProps } from '../actions/check-receipt-actions';

type ReceiptInfoInlineToolbarProps = PropsWithChildren & {
  selectedItem: ReceiptInfoInlineCheckButtonProps['selectedItem'];
  onClick?: ReceiptInfoInlineCheckButtonProps['onClick'];
};

export const ReceiptInfoInlineToolbar = (props: ReceiptInfoInlineToolbarProps) => {
  const { children, ...restOfProps } = props;

  return (
    <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
      {children}

      <DataGridActionbar>
        <ReceiptInfoInlineCheckButton {...restOfProps} />
      </DataGridActionbar>
    </Stack>
  );
};
