import { useParams } from 'react-router';
import { Stack, Typography } from '@mui/material';

import { DataGridContainer } from '@/components/DataGrid';
import { PageContent } from '@/layouts/Page';
import { Toolbar } from '../shared';
import { ReceiptInfoDataGrid } from './data-grids';
import { ReceiptInfoDataGridMainToolbar } from './toolbars';
import { CancelReasonCard, ReceiptStatus } from './ui';
import { useReceiptInfo } from './useReceiptInfo';
import { PharmacistsControlDetailReceiptInfoPathParams } from '@/router/paths/dorim-promo.path';
import { formatDateTime } from '@/helpers/format.helpers';
import { DEFAULT_FULL_DATE_TIME_FORMAT } from '@/constants/date-formats.constants';
import { RECEIPT_STATUS_CANCELLED } from '@/constants/receipt-status.constants';
import { useResetReceiptInfoStoreOnUnmount } from './receipt-info.store';

const ReceiptInfoPage = () => {
  useResetReceiptInfoStoreOnUnmount();
  const { receiptId } = useParams<PharmacistsControlDetailReceiptInfoPathParams>();
  const { isFetching, data: receipt } = useReceiptInfo({ receiptId });

  if (!receipt) return null;

  const { cancel_reason, number, scanned_at, status } = receipt;
  const hasCancelReason = status === RECEIPT_STATUS_CANCELLED && !!cancel_reason;

  return (
    <PageContent>
      <Stack spacing={1} flexGrow={1}>
        <Toolbar isLoading={isFetching} isShowTabs={false}>
          <Stack direction="row" spacing={2} alignItems="center">
            <Stack direction="row" spacing={2} alignItems="baseline">
              <Typography variant="Title/Bold/large">Чек №{number}</Typography>
              <Typography variant="Body/medium" color="neutral.900">
                {formatDateTime(scanned_at, DEFAULT_FULL_DATE_TIME_FORMAT)}
              </Typography>
            </Stack>

            <ReceiptStatus status={status} />
          </Stack>
        </Toolbar>

        {hasCancelReason && <CancelReasonCard reason={cancel_reason} />}

        <DataGridContainer>
          <ReceiptInfoDataGridMainToolbar />
          <ReceiptInfoDataGrid />
        </DataGridContainer>
      </Stack>
    </PageContent>
  );
};

export default ReceiptInfoPage;
