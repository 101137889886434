import { PageContainer } from '@/layouts/Page';
import { Header } from '@/layouts/Header';
import { Outlet } from 'react-router';

export const Layout = () => {
  return (
    <PageContainer title="Dorim.Promo • Контроль провизоров">
      <Header hasBreadcrumbs />

      <Outlet />
    </PageContainer>
  );
};
