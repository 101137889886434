import { ReportApi } from '@/api/domains/report.api';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

export const useCheckReceiptBinding = () => {
  const snackbar = useSnackbar();

  return useMutation({
    mutationFn: ReportApi.checkReceiptBinding,
    onSuccess: async () => {
      snackbar.enqueueSnackbar('Товар отправлен на проверку', { variant: 'info' });
    },
  });
};
