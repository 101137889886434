import { Link as RouterLink } from 'react-router-dom';
import { Box, Skeleton, Tab, Tabs, styled } from '@mui/material';

import { Infobar } from '@/components/InfoBar';
import { useMatchLinks } from '@/hooks/useMatchLinks';
import { DorimPromoPaths } from '@/router/paths/dorim-promo.path';
import { usePharmacistIdFromPathParams } from './usePharmacistIdFromPathParams';

type ToolbarProps = React.PropsWithChildren<{}> & {
  isLoading?: boolean;
  isShowTabs?: boolean;
};

const ToolbarTabs = () => {
  const pharmacistId = usePharmacistIdFromPathParams();
  const stringifiedPharmacistId = String(pharmacistId);

  const receiptsPath = DorimPromoPaths.generatePharmacistsControlDetailReceiptsPath({
    pharmacistId: stringifiedPharmacistId,
  });
  const walletPath = DorimPromoPaths.generatePharmacistsControlDetailWalletPath({
    pharmacistId: stringifiedPharmacistId,
  });

  const matchedLink = useMatchLinks(receiptsPath, walletPath);
  const pathname = matchedLink?.pathname;

  if (!pathname) return null;

  return (
    <Tabs value={matchedLink.pathname} sx={{ pl: 1 }} textColor="primary" indicatorColor="primary">
      <Tab component={RouterLink} to={receiptsPath} value={receiptsPath} replace label="Чеки" />
      <Tab component={RouterLink} to={walletPath} value={walletPath} replace label="Кошелёк" />
    </Tabs>
  );
};

const StyledRoot = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 1, 0),
  backgroundColor: theme.palette.background.neutral,
})) as typeof Box;

type InfobarSkeleton = {
  isShowTabs?: boolean;
};

const InfobarSkeleton = (props: InfobarSkeleton) => {
  const { isShowTabs = true } = props;
  return (
    <StyledRoot>
      <Infobar footer={isShowTabs && <ToolbarTabs />}>
        <Skeleton variant="text" animation="wave" width={400} />
      </Infobar>
    </StyledRoot>
  );
};

export const Toolbar = (props: ToolbarProps) => {
  const { isLoading, children, isShowTabs = true } = props;

  if (isLoading) return <InfobarSkeleton isShowTabs={isShowTabs} />;

  return (
    <StyledRoot>
      <Infobar footer={isShowTabs && <ToolbarTabs />}>{children}</Infobar>
    </StyledRoot>
  );
};
