import { useUnmount } from 'react-use';
import { devtools } from 'zustand/middleware';
import { createStoreWithReset } from '@/libs/zustand';
import { ReceiptInfoDataGridSelectedRowsData } from './types';

type ReceiptInfoStore = {
  selectedRows: ReceiptInfoDataGridSelectedRowsData;

  actions: {
    setSelectedRows: (rows: ReceiptInfoDataGridSelectedRowsData) => void;
    resetSelectedRows: VoidFunction;
  };
};

const [useReceiptInfoStore, resetReceiptInfoStore] = createStoreWithReset<ReceiptInfoStore>()(
  devtools(
    set => ({
      selectedRows: {},

      actions: {
        setSelectedRows: selectedRows => set({ selectedRows }, false, 'setSelectedRows'),
        resetSelectedRows: () => set({ selectedRows: [] }, false, 'resetSelectedRows'),
      },
    }),
    {
      name: 'receipt-info-store',
      enabled: process.env.NODE_ENV === 'development',
    },
  ),
);

export const useReceiptInfoStoreActions = () => useReceiptInfoStore(state => state.actions);
export const useReceiptInfoStoreSelectedRows = () => useReceiptInfoStore(state => state.selectedRows);

export const useResetReceiptInfoStoreOnUnmount = () => useUnmount(resetReceiptInfoStore);
