import { paramsToQueryKey } from '@/helpers/transformation.helpers';
import type { FetchReceiptsParams, FetchReceiptParams } from './receipts.api';

const PREFIX = 'receipts';

export const ReceiptsQueryKeys = {
  root: [PREFIX],

  getReceipts: (params?: FetchReceiptsParams) => paramsToQueryKey([PREFIX, 'list'], params),
  getReceipt: (params?: Partial<FetchReceiptParams>) => paramsToQueryKey([PREFIX, 'receipt'], params),
};
