import { httpClient } from '@/libs/axios/http-client';

export type CheckReceiptBindingParams = {
  error_type_ids: number[];
  receipt_id?: number;
  receipt_item_ids?: number[];
};

export const ReportApi = {
  checkReceiptBinding: (params: CheckReceiptBindingParams) => httpClient.post<void>('report', params),
};
