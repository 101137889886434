import { ReceiptsApi } from '@/api/domains/receipts.api';
import { ReceiptsQueryKeys } from '@/api/domains/receipts.query-keys';
import { useQuery } from '@tanstack/react-query';

type UseReceiptInfoParams = {
  receiptId: string | undefined;
};

export const useReceiptInfo = ({ receiptId: passedReceiptId }: UseReceiptInfoParams) => {
  const isEnabled = passedReceiptId !== undefined;
  const receiptId = Number(passedReceiptId);

  return useQuery({
    queryKey: ReceiptsQueryKeys.getReceipt({ receiptId }),
    queryFn: async () => {
      if (isEnabled) {
        return await ReceiptsApi.fetchReceipt({ receiptId });
      }
    },
    staleTime: Infinity,
    enabled: isEnabled,
  });
};
