import { Checkbox, Collapse, IconButton, Stack, styled, SvgIcon, Tooltip, Typography } from '@mui/material';
import CloseArrowIcon from '@mui/icons-material/KeyboardArrowDown';
import UpArrowIcon from '@mui/icons-material/KeyboardArrowUp';
import { ReactComponent as WarningIcon } from '@/assets/icons-2.0/warning.svg';

import { DataGrid, DataGridInstanceId } from '@/components/DataGrid';
import { useReceiptInfoDataGrid } from './useReceiptInfoDataGrid';
import { useReceiptInfoStoreSelectedRows } from '../receipt-info.store';
import { LicenseHolderAndMakerInfo } from '@/components/LicenseHolderAndMakerInfo';
import { TextLabel } from '../ui';
import { ReceiptInfoInlineToolbar } from '../toolbars/ReceiptInfoInlineToolbar';
import { formatMakerName, formatNumberByThousands } from '@/helpers/format.helpers';

const INSTANCE_ID: DataGridInstanceId = 'receipt-info';

type DetailInfoBlockProps = {
  title: string;
  value?: string | string[] | number | number[];
};

const DetailInfoBlock = (props: DetailInfoBlockProps) => {
  const { title, value } = props;

  const isValueArray = Array.isArray(value);
  const valueString = isValueArray ? value[0] : value;

  return (
    <Stack minWidth={124}>
      <Typography variant="Body/small" color="neutral.700">
        {title}
      </Typography>

      <Stack spacing={1} direction="row" alignItems="center">
        <Typography noWrap variant="Body/medium" component="p">
          {valueString ?? '-'}
        </Typography>

        {isValueArray && value.length > 1 && (
          <Tooltip
            arrow
            placement="top"
            title={
              <Typography whiteSpace="pre-line" color="neutral.500" variant="Body/small">
                {value.join('\n')}
              </Typography>
            }
          >
            <span>
              <TextLabel>
                <Typography variant="Body/Bold/small" color="inherit">
                  +{value.length - 1}
                </Typography>
              </TextLabel>
            </span>
          </Tooltip>
        )}
      </Stack>
    </Stack>
  );
};

const StyledDataGrid = styled(DataGrid)({
  '.InovuaReactDataGrid__row-details': {
    zIndex: 0,
    height: '0 !important',
    backgroundColor: 'aqua !important',
  },

  '.InovuaReactDataGrid__cell': {
    height: '100% !important',
  },

  '.InovuaReactDataGrid__locked-end-wrapper': {
    backgroundColor: '#ffffff !important',
    height: '100% !important',

    '.InovuaReactDataGrid__cell': {
      height: '100% !important',
    },
  },

  '.InovuaReactDataGrid__row-active-borders-inner': {
    border: '0px solid white !important',
  },
}) as typeof DataGrid;

export const ReceiptInfoDataGrid = () => {
  const selectedRows = useReceiptInfoStoreSelectedRows();
  const { rows, isLoading, handleSelectionChange } = useReceiptInfoDataGrid();

  return (
    <StyledDataGrid
      instanceId={INSTANCE_ID}
      loading={isLoading}
      selected={selectedRows}
      onSelectionChange={handleSelectionChange}
      idProperty="id"
      dataSource={rows}
      rowExpandHeight={100}
      rowExpandColumn={{
        width: 68,
        headerProps: {
          style: {
            borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
          },
        },
        resizable: false,
        header: '',
        render: ({ rowExpanded, toggleRowExpand }) => {
          const handleClick = (e: React.MouseEvent) => {
            e.stopPropagation();
            toggleRowExpand();
          };

          return (
            <IconButton onClick={handleClick} color="primary">
              {rowExpanded ? <UpArrowIcon /> : <CloseArrowIcon />}
            </IconButton>
          );
        },
      }}
      renderRowDetails={() => null}
      showCellBorders="horizontal"
      checkboxColumn={{
        width: 50,
        textAlign: 'center',
        renderCheckbox: ({ onChange, checked }) => (
          <Checkbox
            value={!!checked}
            disableRipple
            color="secondary"
            checked={!!checked}
            indeterminate={checked === null}
            sx={{ p: 0 }}
            onClick={e => {
              e.stopPropagation();
              onChange(!checked);
            }}
          />
        ),
      }}
      columns={[
        {
          name: 'id',
          header: 'ID',
          width: 88,
        },
        {
          name: 'drugInReceipt',
          header: 'Товар в чеке',
          defaultFlex: 1,
          defaultWidth: 379,
          minWidth: 330,
          render: ({ value, data, rowExpanded, rowIndex, setRowSelected }) => {
            if (!value) return '-';

            const { drug, nomenclature_name: name } = value;
            const { drugInCatalog } = data;

            return (
              <ReceiptInfoInlineToolbar
                selectedItem={data}
                onClick={event => {
                  event.stopPropagation();
                  setRowSelected(rowIndex, data);
                }}
              >
                <Stack spacing={1} flex={1} minWidth={0}>
                  <Stack justifyContent="flex-start">
                    <Stack direction="row" spacing={1}>
                      <Tooltip title={name} placement="top" arrow>
                        <Typography noWrap variant="inherit">
                          {name}
                        </Typography>
                      </Tooltip>
                      {!drugInCatalog && (
                        <Tooltip title="Товар не распознан" placement="top" arrow>
                          <span>
                            <SvgIcon component={WarningIcon} fontSize="small" color="warning" />
                          </span>
                        </Tooltip>
                      )}
                    </Stack>

                    {drug?.maker && (
                      <Typography variant="Body/small" color="neutral.700" noWrap>
                        {formatMakerName(drug.maker.name, drug.maker.country_name) ?? '-'}
                      </Typography>
                    )}
                  </Stack>

                  <Collapse in={rowExpanded} timeout={0}>
                    <Stack direction="row" spacing={2}>
                      <DetailInfoBlock title="Штрих-код" value={value.barcode} />
                      <DetailInfoBlock title="Код ИКПУ" value={value.ikpucode} />
                    </Stack>
                  </Collapse>
                </Stack>
              </ReceiptInfoInlineToolbar>
            );
          },
        },
        {
          name: 'drugInCatalog',
          header: 'Товар в справочнике',
          defaultFlex: 1,
          defaultWidth: 379,
          minWidth: 290,
          render: ({ value, rowExpanded }) => {
            if (!value) return '-';

            const { name, maker, license_holder } = value;

            return (
              <Stack spacing={1}>
                <Stack justifyContent="flex-start">
                  <Tooltip title={name} placement="top" arrow>
                    <Typography noWrap variant="inherit">
                      {name}
                    </Typography>
                  </Tooltip>

                  {maker ? (
                    <LicenseHolderAndMakerInfo licenseHolder={license_holder} maker={maker} size="small">
                      <Typography variant="Body/small" color="neutral.700" lineHeight="normal">
                        {formatMakerName(maker.name, maker.country_name) ?? '-'}
                      </Typography>
                    </LicenseHolderAndMakerInfo>
                  ) : (
                    '-'
                  )}
                </Stack>

                <Collapse in={rowExpanded} timeout={0}>
                  <Stack direction="row" spacing={2}>
                    <DetailInfoBlock title="Штрих-код" value={value.barcode || value.barcodes} />
                    <DetailInfoBlock title="Код ИКПУ" value={value.ikpu} />
                  </Stack>
                </Collapse>
              </Stack>
            );
          },
        },
        {
          name: 'dorimPromo',
          header: 'Dorim.Promo',
          defaultWidth: 98,
          render: ({ value }) => <Checkbox checked={value} disabled inputProps={{ style: { cursor: 'default' } }} />,
        },
        {
          name: 'count',
          header: 'Кол-во',
          defaultWidth: 88,
          render: ({ value }) => formatNumberByThousands(value),
        },
        {
          name: 'price',
          header: 'Цена',
          defaultWidth: 136,
          render: ({ value }) => formatNumberByThousands(value),
        },
        {
          name: 'sum',
          header: 'Сумма',
          defaultWidth: 136,
          render: ({ value }) => formatNumberByThousands(value),
        },
      ]}
    />
  );
};
