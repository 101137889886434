import { Fragment } from 'react';
import type { Assign } from 'utility-types';
import { Stack, Card, type StackProps, type CardProps, styled, Divider } from '@mui/material';
import { type RoundedButtonProps } from '../buttons';

type SlotRootProps = Omit<Assign<CardProps, StackProps>, 'component'>;

export type InfobarProps = React.PropsWithChildren<
  {
    footer?: React.ReactNode;
  } & (
    | {
        button?: never;
        slotProps?: {
          root?: SlotRootProps;
          button?: Omit<RoundedButtonProps, 'component'> & { 'data-testid': string };
        };
      }
    | {
        button?: React.ReactNode;
        slotProps?: {
          root?: SlotRootProps;
          button?: never;
        };
      }
  )
>;

const StyledRoot = styled(Card)({
  width: '100%',
  flexShrink: 0,
}) as typeof Card;

export const Infobar = (props: InfobarProps) => {
  const { slotProps, children, button, footer } = props;

  return (
    <StyledRoot component={Stack} variant="outlined" sx={{ width: '100%' }} {...slotProps?.root}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ width: '100%', minHeight: '72px', flexShrink: 0, p: 2, pl: 3 }}
      >
        {children}
        {button}
      </Stack>

      {footer && (
        <Fragment>
          <Divider />
          {footer}
        </Fragment>
      )}
    </StyledRoot>
  );
};
