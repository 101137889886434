import { LabelColor } from '@/components/labels';

export type ReceiptStatusId =
  | typeof RECEIPT_STATUS_CANCELLED
  | typeof RECEIPT_STATUS_CHECKING
  | typeof RECEIPT_STATUS_COMPLETED
  | typeof RECEIPT_STATUS_UNKNOWN;

export const RECEIPT_STATUS_CANCELLED = 30;
export const RECEIPT_STATUS_CHECKING = 288;
export const RECEIPT_STATUS_COMPLETED = 299;
export const RECEIPT_STATUS_UNKNOWN = 0;

export const ReceiptStatusNameByStatusId = {
  [RECEIPT_STATUS_CANCELLED]: 'Отменен',
  [RECEIPT_STATUS_CHECKING]: 'Проверяется',
  [RECEIPT_STATUS_COMPLETED]: 'Выполнено',
  [RECEIPT_STATUS_UNKNOWN]: 'Неизвестно',
};

export const ReceiptColorByStatusId: Record<ReceiptStatusId, LabelColor> = {
  [RECEIPT_STATUS_CANCELLED]: 'default',
  [RECEIPT_STATUS_CHECKING]: 'warning',
  [RECEIPT_STATUS_COMPLETED]: 'success',
  [RECEIPT_STATUS_UNKNOWN]: 'default',
};
