import { lazy } from 'react';

export const ReceiptsPage = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "ReceiptsPage" */
      './ReceiptsPage'
    ),
);
